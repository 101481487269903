	/*
  	Flaticon icon font: Flaticon
  	Creation date: 01/06/2020 10:57
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-question:before { content: "\f100"; }
.flaticon-share:before { content: "\f101"; }
.flaticon-back:before { content: "\f102"; }
.flaticon-next:before { content: "\f103"; }
.flaticon-arrow-up:before { content: "\f104"; }
.flaticon-arrow-down:before { content: "\f105"; }
.flaticon-disc:before { content: "\f106"; }
.flaticon-play-button:before { content: "\f107"; }
.flaticon-plus:before { content: "\f108"; }
.flaticon-minus:before { content: "\f109"; }
.flaticon-information:before { content: "\f10a"; }
.flaticon-right-arrow:before { content: "\f10b"; }
.flaticon-guarantee:before { content: "\f10c"; }
.flaticon-car-door:before { content: "\f10d"; }
.flaticon-gearbox:before { content: "\f10e"; }
.flaticon-location:before { content: "\f10f"; }
.flaticon-radio:before { content: "\f110"; }
.flaticon-tyre:before { content: "\f111"; }
.flaticon-edit:before { content: "\f112"; }
.flaticon-round:before { content: "\f113"; }
.flaticon-home-phone:before { content: "\f114"; }
.flaticon-down-arrow:before { content: "\f115"; }
.flaticon-filter:before { content: "\f116"; }
.flaticon-car-compact:before { content: "\f117"; }
.flaticon-facebook-logo:before { content: "\f118"; }
.flaticon-location-arrow:before { content: "\f119"; }
.flaticon-check:before { content: "\f11a"; }
.flaticon-blueetooth:before { content: "\f11b"; }
.flaticon-round-error-symbol:before { content: "\f11c"; }
.flaticon-control:before { content: "\f11d"; }
.flaticon-usb-cable:before { content: "\f11e"; }
.flaticon-instagram-logo:before { content: "\f11f"; }
.flaticon-life-bouy:before { content: "\f120"; }
.flaticon-date-time:before { content: "\f121"; }
.flaticon-bars:before { content: "\f122"; }
.flaticon-shield:before { content: "\f123"; }
.flaticon-world-wide-web:before { content: "\f124"; }
.flaticon-lock:before { content: "\f125"; }
.flaticon-book:before { content: "\f126"; }
.flaticon-document:before { content: "\f127"; }
.flaticon-rental:before { content: "\f128"; }
.flaticon-more:before { content: "\f129"; }
.flaticon-more-1:before { content: "\f12a"; }
.flaticon-question-mark-in-dark-circle:before { content: "\f12b"; }
.flaticon-clock-circular-outline:before { content: "\f12c"; }
.flaticon-alarm-clock:before { content: "\f12d"; }
.flaticon-4wd-icon:before { content: "\f12e"; }
.flaticon-out:before { content: "\f12f"; }
.flaticon-email:before { content: "\f130"; }
.flaticon-car-insurance:before { content: "\f131"; }
.flaticon-price:before { content: "\f132"; }
.flaticon-plane:before { content: "\f133"; }
.flaticon-bookmark-star:before { content: "\f134"; }
.flaticon-envelope:before { content: "\f135"; }
.flaticon-printing-tool:before { content: "\f136"; }
.flaticon-passage-of-time:before { content: "\f137"; }
.flaticon-fast:before { content: "\f138"; }
.flaticon-star:before { content: "\f139"; }
.flaticon-right-arrow-1:before { content: "\f13a"; }
.flaticon-down-arrow-1:before { content: "\f13b"; }
.flaticon-calendar:before { content: "\f13c"; }
.flaticon-comment:before { content: "\f13d"; }
.flaticon-image:before { content: "\f13e"; }
.flaticon-left-arrow:before { content: "\f13f"; }
.flaticon-sort-by-attributes:before { content: "\f140"; }
.flaticon-search:before { content: "\f141"; }
.flaticon-car-location-icon:before { content: "\f142"; }
.flaticon-line-icon:before { content: "\f143"; }
.flaticon-phone-icon:before { content: "\f144"; }
.flaticon-gear-icon:before { content: "\f145"; }
.flaticon-cursor:before { content: "\f146"; }
.flaticon-warning:before { content: "\f147"; }
.flaticon-credit-card:before { content: "\f148"; }
.flaticon-baht:before { content: "\f149"; }
.flaticon-file:before { content: "\f14a"; }
.flaticon-car:before { content: "\f14b"; }
.flaticon-flaticon1558008084-svg:before { content: "\f14c"; }
.flaticon-descendant:before { content: "\f14d"; }
.flaticon-close:before { content: "\f14e"; }
.flaticon-calendar1x:before { content: "\f14f"; }
.flaticon-arrow-left1x:before { content: "\f150"; }
.flaticon-location1x:before { content: "\f151"; }
.flaticon-arrow-left1x-1:before { content: "\f152"; }
.flaticon-arrow-right1x:before { content: "\f153"; }
.flaticon-arrow-up1x:before { content: "\f154"; }
.flaticon-bag1x:before { content: "\f155"; }
.flaticon-bluetooth1x:before { content: "\f156"; }
.flaticon-chat1x:before { content: "\f157"; }
.flaticon-check-correct1x-1:before { content: "\f158"; }
.flaticon-check-wrong1x:before { content: "\f159"; }
.flaticon-seat1x:before { content: "\f15a"; }
.flaticon-uploaddoc1x:before { content: "\f15b"; }
.flaticon-circle-add1x:before { content: "\f15c"; }
.flaticon-line1x:before { content: "\f15d"; }
.flaticon-receipt1x:before { content: "\f15e"; }
.flaticon-arrow-down1x:before { content: "\f15f"; }
.flaticon-calendar1x-1:before { content: "\f160"; }
.flaticon-car1x:before { content: "\f161"; }
.flaticon-cd1x:before { content: "\f162"; }
.flaticon-clipboard1x:before { content: "\f163"; }
.flaticon-doc1x:before { content: "\f164"; }
.flaticon-email1x:before { content: "\f165"; }
.flaticon-fb1x:before { content: "\f166"; }
.flaticon-gearshift1x:before { content: "\f167"; }
.flaticon-idcard1x:before { content: "\f168"; }
.flaticon-infomation1x:before { content: "\f169"; }
.flaticon-invoice1x:before { content: "\f16a"; }
.flaticon-menu1x:before { content: "\f16b"; }
.flaticon-phone1x:before { content: "\f16c"; }
.flaticon-profile1x:before { content: "\f16d"; }
.flaticon-radio1x:before { content: "\f16e"; }
.flaticon-registered1x:before { content: "\f16f"; }
.flaticon-creditcard1x:before { content: "\f170"; }
.flaticon-qustion1x:before { content: "\f171"; }
.flaticon-star-fill1x:before { content: "\f172"; }
.flaticon-star-stoke1x:before { content: "\f173"; }
.flaticon-usb1x:before { content: "\f174"; }
.flaticon-wheel1x:before { content: "\f175"; }
.flaticon-send1x:before { content: "\f176"; }
.flaticon-trash1x:before { content: "\f177"; }
.flaticon-point1x:before { content: "\f178"; }
.flaticon-check-correctfill1x-2:before { content: "\f179"; }
.flaticon-check-wrongtfill1x-1:before { content: "\f17a"; }
.flaticon-check-correct1x-2:before { content: "\f17b"; }
.flaticon-like-1:before { content: "\f17c"; }
.flaticon-smartphone:before { content: "\f17d"; }
.flaticon-user:before { content: "\f17e"; }
.flaticon-suitcase:before { content: "\f17f"; }