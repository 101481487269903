@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '../../../packages/ui/styles/_colors.scss';

$lg-screen: 992px;
$md-screen: 768px;
$sm-screen: 576px;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        'primary': $primary,
        'primary-light': $very-light-blue,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark,
        'local': $orange,
        'local-light': $pale,
        'inter': $deep-sea-blue,
        'inter-light': $very-light-blue-two,
        'alert-success': $soft-green,
        'alert-error': $soft-red
    ),
    $theme-colors
);

$yiq-contrasted-threshold: 200;

$link-color: theme-color('primary');
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25
        ),
        2: (
            $spacer * 0.5
        ),
        3: $spacer,
        4: (
            $spacer * 1.5
        ),
        5: (
            $spacer * 2
        )
    ),
    $spacers
);

// Grid
$grid-gutter-width: 2rem;

// Customize  Bootstrap 4 Variables

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $dark-blue;
$yiq-text-light: $white;

// Settings for the `<body>` element.
//
$body-bg: $white;
$body-color: $dark-blue;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius: 0.125rem;
$border-radius-lg: 0.3125rem;
$border-radius-sm: 0.125rem;

$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;

$box-shadow: 0 0.1875rem 0.375rem rgba($black, 0.23);

$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

// Navbar

$navbar-padding-y: $spacer / 1.6;

$nav-link-padding-y: 0.75rem;

$navbar-dark-color: $white;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(
    url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
    '#',
    '%23'
) !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: $dark-blue;
$navbar-light-hover-color: rgba($dark-blue, 0.7) !default;
$navbar-light-active-color: rgba($dark-blue, 0.9) !default;
$navbar-light-disabled-color: rgba($dark-blue, 0.3) !default;
$navbar-light-toggler-icon-bg: str-replace(
    url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
    '#',
    '%23'
) !default;
$navbar-light-toggler-border-color: rgba($dark-blue, 0.1) !default;

$navbar-toggler-padding-x: 0;
$navbar-toggler-padding-y: 0.5625rem;

// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0;
$dropdown-spacer: 1rem;
$dropdown-font-size: 0.875rem;
$dropdown-bg: transparent;
$dropdown-border-color: none;
$dropdown-border-radius: $border-radius;
$dropdown-inner-border-radius: calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;

$dropdown-link-color: $dark-blue;
$dropdown-link-hover-color: $dark-blue;
$dropdown-link-hover-bg: darken($pale-grey, 4%);

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.9rem;
$dropdown-item-padding-x: 1.5rem;

$dropdown-header-color: $gray-600 !default;

// Forms
$input-color: $dark-blue;
$input-border-color: $very-light-pink;
$input-box-shadow: none;
$enable-validation-icons: false;

$input-border-radius: $border-radius;

$btn-padding-y-sm: 0.528rem;
$btn-padding-x-sm: 1rem;

$input-placeholder-color: $very-light-pink;
$input-padding-y: 0.875rem;
$input-padding-x: 1rem;
$input-line-height: 1.2;
$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});

$btn-padding-y: 0.875rem;
$btn-padding-x: 1rem;
$btn-font-size: 1.125rem;
$btn-line-height: 1;

// Cards
$card-border-width: 0;
$card-border-radius: $border-radius-lg;
$card-inner-border-radius: $card-border-radius;
$card-border-color: $white;
$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;

.comment-container {
    width: 100%;
    padding: 8px;
    @media screen and (max-width: 768px) {
        padding: 8px 4px;
    }
    .comment__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        > p {
            font-size: 18px;
            font-weight: bold;
            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
            @media screen and (max-width: 376px) {
                font-size: 15px;
            }
        }
        .comment-filter-box {
            position: relative;
            width: 180px;
            padding: 8px 0px;
            border: solid 1px #e2eaf3;
            border-radius: 2px;
            cursor: pointer;
            > div {
                position: relative;
                z-index: 12;
            }
            #comment-filter-select {
                width: 100%;
                appearance: none;
                background-color: transparent;
                border: none;
                padding-left: 16px;
                cursor: pointer;
                @media screen and (max-width: 768px) {
                    padding-left: 8px;
                    font-size: 12px;
                }
                @media screen and (max-width: 376px) {
                    padding-left: 6px;
                    font-size: 12px;
                }
            }
            #comment-filter-select:focus {
                outline: transparent;
            }
            i {
                z-index: 0;
            }
            i::before {
                position: absolute;
                font-size: 14px;
                right: 16px;
                top: 9px;
                margin: 0;
            }
            @media screen and (max-width: 768px) {
                width: 130px;
                i::before {
                    font-size: 12px;
                    right: 8px;
                }
            }
            @media screen and (max-width: 376px) {
                width: 110px;
                i::before {
                    font-size: 10px;
                    right: 6px;
                    top: 11px;
                }
            }
        }
    }
    .comment__list {
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        .comment-total {
            margin-bottom: 12px;
            text-align: center;
            font-weight: bold;
            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
        .comment-pagination {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            a {
                width: 100%;
                padding: inherit;
                padding: 0px 12px;
                font-weight: bold;
                color: #30374b;
            }
            a:focus {
                outline: none;
            }
            .pagination-item {
                margin: 0px 6px;
                padding: 6px 0px;
                background-color: #e2eaf3;
                border-radius: 2px;
                cursor: pointer;
                &:hover {
                    background-color: #4097f9;
                }
                &.active {
                    a {
                        color: #ffffff;
                    }
                    background-color: #0078ff;
                }
            }
            .navigate-btn {
                cursor: pointer;
                i:before {
                    margin: 0;
                    font-size: 12px;
                    color: #0078ff;
                }
                &--disable {
                    display: none;
                }
            }
        }
    }
}
