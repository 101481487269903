.noscroll {
    overflow: hidden;
    position: fixed;
    max-width: 100%;
}
.flaticon{
    &__md{
        &::before{
            font-size: 30px!important;
        }
    }
}
.clearfix{
    :after {
        clear: both;
        content: '.';
        display: block;
        font-size: 0;
        height: 0;
        line-height: 0;
        visibility: hidden;
    }
}
.float-left{
    float: left;
    clear: left;
}
.float-right{
    float: right;
    clear:right;
}
.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.zero-margin-top {
    margin-top: 0;
}

.zero-margin-bottom {
    margin-bottom: 0;
}

.margin-top-xs {
    margin-top: 10px;
}

.margin-top-sm {
    margin-top: 15px;
}

.margin-top-md {
    margin-top: 30px;
}

.margin-bottom-xs {
    margin-bottom: 10px;
}

.margin-bottom-sm {
    margin-bottom: 15px;
}

.margin-bottom-md {
    margin-bottom: 30px;
}

.margin-bottom-big {
    margin-bottom: 45px;
}

.zero-padding-right {
    padding-right: 0;
}

.zero-padding-left {
    padding-left: 0;
}

.zero-margin {
    margin: 0;
}

.margin-auto {
    margin: auto;
}

.block {
    display: block;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.text-decoration-note {
    text-decoration: none !important;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reset_icon_margin {
    &::before {
        margin-left: 0 !important;
    }
    margin-left: 0;
}

.full-width {
    width: 100%;
    height: 100%;
}

.z-99 {
    position: absolute;
    z-index: 99;
}

.border-red {
    border: 1px solid $red !important;
}

.cursor-default {
    cursor: default !important;
}