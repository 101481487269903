//Drivehub Colours

$dark-blue: #30374b;
$deep-blue: #0053ba;
$blue: #0078ff;
$deep-sea-blue: #00508d;
$very-light-pink: #c3c3c3;
$brownish-grey: #6f6f6f;
$very-light-blue: #e2eaf3;
$pale-grey: #eef3f8;
$orange: #ff8d41;
$red: #d0021b;
$soft-red: #ffc5c1;
$white: #ffffff;
$green: #49a35c;
$soft-green: #c3e6cb;
$pale: #ffddd3;
$very-light-blue-two: #d8edfd;
$line: #00b900;
$grey-smoke: #f1f1f1;
$soft-grey: #c3cada;
$grey: #7a7b80;
$yellow: #eec315;

//Search Colours
$lighter-gray: #f9f9f9;
$light-gray: #edeff2;
$gray: #8c8c8c;
$dark-gray: #323232;
$dark-yellow: #d5ab00;
$dark-green: #018856;
$light-green: #37c883;
$line-green: #2cbf13;
$lime-green: #57bc90;
$black: #000;

$primary: $blue;
$secondary: #6c757d;
$success: $green;
$info: $very-light-blue-two;
$warning: $yellow;
$danger: $red !default;
$light: $white;
$dark: $dark-blue;
