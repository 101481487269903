// Example: change body color
// $body-bg: $gray-900;

// Webfont Anti aliased
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button,
a {
    &:focus {
        outline: none !important;
    }
}
a {
    &.call {
        > i {
            color: $green;
        }
    }
    &.lineat {
        > i {
            color: $line;
        }
    }
    &.auth-icon {
        > i:before {
            font-size: 18px;
        }
    }
}

// Grid

// Grid columns

.no-gutters {
    margin-right: 0;
    margin-left: 0;
    > .col,
    > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }
}

.bg-gray {
    background-color: $pale-grey;
}

.color-green {
    color: $green;
}

// Effect
.dh-animate-pulse {
    cursor: pointer;
    box-shadow: 5 5 5 #0078ff;
    animation: dh-pulse 2s infinite;
    &:hover {
        -webkit-animation: none;
        animation: none;
    }
}
@-webkit-keyframes dh-pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 #0078ff;
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    to {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}
@keyframes dh-pulse {
    0% {
        box-shadow: 0 0 0 0 #0078ff;
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    to {
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}
.bg-light {
    .form-control {
        border: solid 1px $very-light-pink;
    }
}

.form-group {
    > label {
        font-size: 0.75rem;
        color: $brownish-grey;
    }

    .selectContainer {
        position: relative;
        &::after {
            content: '\e814';
            font-family: 'drivehub-icon';
            position: absolute;
            right: 1.5rem;
            top: 0.825rem;
            pointer-events: none;
        }
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

// Badge
$badge-font-size: 0.75rem;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: 0rem;
$badge-padding-x: 0.5rem;
$badge-border-radius: $border-radius-lg;

.badge {
    height: 1.5rem;
    line-height: 1.5rem !important;
    &.badge-sm {
        font-size: 0.5rem;
        font-weight: 700;
        height: 19px !important;
        line-height: 19px !important;
        padding: 0 0.35rem 0 0.25rem !important;
        i {
            line-height: 1.3rem;
            vertical-align: middle;
            &:before {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
    i {
        &:before {
            margin: 0;
            margin-right: 6px;
            // color: $white;
            $badge-font-size: 1.5rem;
        }
    }
}

// Accordian

.accordion {
    .card {
        &:not(:first-child) {
            border-top: 1px solid lighten($very-light-pink, 15%);
        }
        .card-header {
            background-color: lighten($very-light-pink, 19%);
            h3 {
                button {
                    width: 100%;
                    text-align: left;
                    font-size: 1.125rem;
                    line-height: 1.4;
                    position: relative;
                    padding-right: 2rem;
                    font-weight: 600;
                    color: $dark-blue;
                    &::after {
                        content: '\e817';
                        position: absolute;
                        right: 1rem;
                        font-family: 'drivehub-icon';
                        color: $blue;
                    }
                    &.collapsed {
                        &::after {
                            content: '\e814';
                            position: absolute;
                            right: 1rem;
                            font-family: 'drivehub-icon';
                            top: calc(50% - 10px);
                        }
                    }
                }
            }
        }
        .card-body {
            padding: 1rem 1.5rem;
        }
    }
}

.wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    &::-webkit-scrollbar {
        display: none;
    }
    .card-widget {
        display: inline-block;
    }
}
.card-container {
    &.scrollable {
        display: flex;
        overflow-x: auto;
        scroll-snap-type: x proximity;
        ::-webkit-scrollbar {
            width: 0px; /* Remove scrollbar space */
            background: transparent; /* Optional: just make scrollbar invisible */
            display: none;
        }
    }
    // Card : transparent
    &.transparent {
        .card {
            background-color: transparent;
            text-align: center;
            img {
                max-width: 100%;
                &.lazyloading,
                &.lazyload {
                    height: 100px;
                    padding: 0;
                    margin-left: calc(50% - 50px);
                    margin-top: calc(30% - 8px);
                }
            }
        }
    }
    // Card : Overlay style
    &.overlay {
        .card {
            position: relative;
            width: 208px;
            height: 117px;
            .card-img {
                height: 117px;
                .card-img-top {
                    border-radius: $card-inner-border-radius;
                }
                img {
                    &.lazyloading,
                    &.lazyload {
                        height: 16px;
                        width: 100px;
                        padding: 0;
                        margin-left: calc(50% - 50px);
                        margin-top: calc(30% - 8px);
                    }
                }
            }
            .card-body {
                position: absolute;
                color: $white;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: flex-end;
                background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.46) 100%);
                border-radius: $card-inner-border-radius;
            }
        }
    }
    &.two-columns-fixed {
        display: flex;
        padding: 0 15px 0 15px;
        flex-wrap: wrap;
        margin-left: 0px;
        margin-right: -15px;
        .card {
            flex: 0 0 50%;
            max-width: calc(50% - 16px);
            display: flex;
            flex-direction: column;
            margin: 0 1rem 1rem 0;

            .card-img {
                display: none;
            }
            .card-body {
                width: 100%;
                padding: 0.875rem 1rem;
                align-content: flex-start;
                > div {
                    width: 100%;
                }
                .btn {
                    width: auto;
                    margin-top: 1rem;
                }
                .info {
                    font-size: 0.875rem;
                }
                .price {
                    text-align: center;
                    font-size: 0.75rem;
                    line-height: 1.2;
                    max-width: 98px;
                }
            }
        }
    }
    // Card : Img top
    &.two-columns {
        .card {
            width: 295px;
            margin: 0 1rem 0.5rem 1rem;
            .card-img {
                height: 170px;
            }
            .card-body {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                h2 {
                    font-size: $h4-font-size;
                    line-height: 1.2;
                }

                p {
                    font-size: $h6-font-size;
                }
                .btn {
                    width: 100%;
                    margin-top: 0.5rem;
                }
            }
        }
    }
    &.car {
        .card {
            width: 268px;
            .card-img {
                img {
                    &.lazyloading,
                    &.lazyload {
                        height: 16px;
                        width: 100px;
                        padding: 0;
                        margin: 30% auto;
                    }
                }
            }
            .card-body {
                .btn {
                    width: 100%;
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                }
                .info {
                    .badge {
                        &:not(:last-child) {
                            margin-bottom: 5px;
                        }
                    }
                }
                .price {
                    display: grid;
                    justify-content: flex-end;
                    > div {
                        text-align: center;
                        h3 {
                            line-height: 1;
                        }
                        del {
                            font-weight: bold;
                            text-align: center;
                            line-height: 1.2;
                            text-decoration: none;
                            position: relative;
                            &:before {
                                content: ' ';
                                display: block;
                                width: 100%;
                                border-top: 2px solid $red;
                                height: 5px;
                                position: absolute;
                                bottom: 5px;
                                right: 0;
                                transform: rotate(-14deg);
                            }
                        }
                        > div {
                            text-align: center;
                            font-size: 0.75rem;
                            line-height: 1.2;
                        }
                    }
                    &.slash {
                        > div {
                            h3 {
                                color: $red;
                            }
                        }
                    }
                }
                .slash {
                    h3 {
                        color: $red;
                    }

                    .strikethrough {
                        color: $red;
                        position: relative;
                        font-size: 1rem;
                    }

                    .strikethrough:before {
                        position: absolute;
                        content: '';
                        left: 0;
                        top: 50%;
                        right: 0;
                        border-top: 2px solid;
                        border-color: inherit;

                        -webkit-transform: rotate(-15deg);
                        -moz-transform: rotate(-15deg);
                        -ms-transform: rotate(-15deg);
                        -o-transform: rotate(-15deg);
                        transform: rotate(-15deg);
                    }

                    .price {
                        color: $dark-blue;
                        position: relative;
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    .card {
        min-width: 131px;
        margin: 0 1rem 0.5rem 1rem;
        a {
            &:not(.btn) {
                color: $body-color;
            }
        }
        .card-img {
            height: 150px;
            position: relative;
            .badge {
                position: absolute;
                top: 0.5rem;
                left: 0.5rem;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                &.lazyloading,
                &.lazyload {
                    height: 16px;
                    width: 100px;
                    padding: 0;
                    margin-left: calc(50% - 50px);
                    margin-top: calc(30% - 8px);
                }
            }
        }
        .card-body {
            p {
                margin-bottom: 0;
            }

            .card-title {
                line-height: 1;
            }
            .divider {
                height: 1px;
                background-color: $very-light-blue;
                margin: 0.5rem 0;
            }
            .card-text {
                font-size: $small-font-size;
                margin-bottom: 0;
                .title {
                    display: block;
                    color: $brownish-grey;
                }
            }
        }
    }
}
///// Tab menu (.list-menu)

.list-menu {
    padding-bottom: 2rem;
    .list-group-item {
        &.active {
            position: relative;
            > div {
                &::before {
                    content: '';
                    position: absolute;
                    bottom: -1rem;
                    left: calc(50% - 8px);
                    transform: rotate(180deg);
                    border-width: 8px;
                    border-style: solid;
                    border-color: transparent transparent $blue;
                    border-image: initial;
                }
            }
        }
    }
}

// Custom style
// Header bar
#mainMenu {
    position: fixed;
    z-index: 1030;
    top: 0;
    width: 100%;
    nav {
        padding: 0rem;
        @extend .navbar-light;
        @extend .bg-light;
        #navbar-auth {
            display: flex;
            align-items: center;
            #auth-toggler::before {
                color: $dark-blue;
                margin: 0;
                font-size: 24px;
                cursor: pointer;
            }
            .navbar-toggler {
                z-index: 900;
            }
        }
        &.collapsed.transparent {
            .navbar-toggler {
                .icon-bar {
                    background: $white !important;
                }
            }
            #auth-toggler::before {
                color: $white !important;
            }
        }
        &.collapsed {
            #overlay-menu {
                display: none;
            }
            .navbar-toggler {
                padding: 0.5rem 1rem;
                z-index: 900;

                .icon-bar {
                    display: block;
                    width: 2rem;
                    height: 3px;
                    border-radius: 4px;
                    margin: 6px 0 7px 0;
                    transition: all 0.2s;
                    background: $dark-blue;
                    /* .navbar closed top .icon-bar no rotation - straight */
                    &:nth-of-type(1) {
                        transform: rotate(0);
                    }

                    /* .navbar open middle .icon-bar visible */
                    &:nth-of-type(2) {
                        opacity: 1;
                        filter: alpha(opacity=100);
                    }

                    /* .navbar open bottom .icon-bar no rotation - straight */
                    &:nth-of-type(3) {
                        transform: rotate(0);
                    }
                }
            }
        }
        #overlay-menu {
            display: block;
            background-color: $pale-grey;
            height: 100vh;
            width: 100vw;
            position: fixed;
            z-index: 889;
            bottom: 0;
            right: 0;
            top: 0;
            left: 0;
            overflow-x: hidden;
            overflow-y: scroll;
            scroll-snap-type: y proximity;
            .menu-header {
                background-color: $white;
                height: 3rem;
                position: fixed;
                width: 100vw;
            }
            .container {
                > ul {
                    padding: 0.5rem 0 0 0;
                    margin-bottom: 0;
                    list-style: none;
                    width: 100%;
                    padding: 0;
                    > li {
                        padding: 0.5rem 0;
                        font-weight: bold;
                        font-size: 1rem;
                        &:first-child {
                            padding: 1rem 0 0.5rem 0;
                        }
                        a {
                            color: $dark-blue;
                            font-weight: normal;
                            span {
                                margin-left: 5px;
                            }
                        }
                        > ul {
                            padding: 0.5rem 1rem 0 1rem;
                            list-style: none;
                            li {
                                padding: 0.5rem 0;
                                font-size: 0.875rem;
                            }
                        }
                    }
                }
            }
        }
        &.transparent {
            @extend .navbar-dark;
            background-color: transparent !important;
            .navbar-brand {
                #dh-logo {
                    background-image: url(/assets/shares/drivehub-logo-transparent.svg);
                }
            }
            .navbar-toggler {
                .icon-bar {
                    background: $white;
                }
            }
            #navbarNavDropdown {
                .navbar-nav {
                    &.contact {
                        .nav-item {
                            &:first-child {
                                position: relative;
                                &::before {
                                    content: ' ';
                                    height: 27px;
                                    width: 1px;
                                    background-color: #ffffff;
                                    position: absolute;
                                    top: 0rem;
                                    left: -4px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .navbar-brand {
            padding: 0rem 1rem;
            #dh-logo {
                background-image: url(/assets/icons/drivehub-logo.svg);
                height: 28px;
                width: 148px;
                background-size: 100%;
                // margin-left: .5rem;
            }
        }
        .navbar-toggler {
            padding: 0.5rem 1rem;
            background: none;
            border: none;
            /* remove the blue outline when active or focused */
            &:active,
            &:focus {
                outline: 0;
            }
            .icon-bar {
                display: block;
                width: 2rem;
                height: 3px;
                border-radius: 4px;
                margin: 6px 0 7px 0;
                transition: all 0.2s;
                background: $dark-blue !important;
                /* .navbar open top .icon-bar rotated down 45° */
                &:nth-of-type(1) {
                    transform: rotate(40deg);
                    transform-origin: 15% 120%;
                }

                /* .navbar open middle .icon-bar invisible */
                &:nth-of-type(2) {
                    opacity: 0;
                    filter: alpha(opacity=0);
                }

                /* .navbar open bottom .icon-bar rotated up 45° */
                &:nth-of-type(3) {
                    transform: rotate(-40deg);
                    transform-origin: -5% -70%;
                }
            }
        }

        #navbarNavDropdown {
            .navbar-nav {
                padding: 0 0.5rem;
                display: flex;
                height: 58px;
                align-items: center;
                .nav-item {
                    &.dropdown {
                        > .dropdown-menu {
                            transition: all 0.7s;
                            top: 20px;
                            overflow: hidden;
                            transform-origin: top center;
                            transform: (1, 0);
                            opacity: 0;
                            display: block;
                            height: 0;
                            margin: 0;
                            .dropdown-link {
                                height: 0;
                                box-shadow: $box-shadow;
                                border-radius: $border-radius;
                                position: relative;
                                background-color: $pale-grey;
                                margin-top: 2rem;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: -1rem;
                                    left: 1.125rem;
                                    transform: rotate(180deg);
                                    border-width: 8px;
                                    border-style: solid;
                                    border-color: $pale-grey transparent transparent;
                                    border-image: initial;
                                    height: 0;
                                    opacity: 0;
                                }
                                span {
                                    margin-left: 5px;
                                }
                            }
                        }
                        > a {
                            font-size: 0.875rem;
                            &::after {
                                content: '\e814';
                                padding-left: 8px;
                                font-family: 'drivehub-icon';
                            }
                        }
                    }
                }
                &.contact {
                    .nav-item {
                        &:first-child {
                            position: relative;
                            &::before {
                                content: ' ';
                                height: 27px;
                                width: 1px;
                                background-color: $very-light-blue;
                                position: absolute;
                                top: 0rem;
                                left: -4px;
                            }
                        }
                        .nav-link {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
        &.transparent #navbarNavDropdown {
            .navbar-nav {
                .nav-item {
                    &.dropdown {
                        > .dropdown-menu {
                            .dropdown-link {
                                margin-top: 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown--auth {
        font-size: 14px;
        &:hover {
            a::after {
                content: '' !important;
            }
        }
        a::after {
            content: '' !important;
        }
        > a {
            cursor: pointer;
        }
        .fetching {
            color: transparent !important;
            background-image: url('/assets/shares/loading.svg');
            background-size: 2rem 2rem;
            background-color: transparent;
            background-position: center;
            background-repeat: no-repeat;
            cursor: none;
        }
        .dropdown-menu {
            width: 250px;
            &.show {
                left: -170px;
            }
            &#dropdown-menu-welcome.show {
                left: -145px;
            }
            &.authenticated .dropdown-item--auth {
                padding: 12px 24px;
                a,
                span {
                    font-size: 16px;
                    color: $dark-blue;
                    i:before {
                        font-size: 16px;
                        color: $dark-blue;
                    }
                }
                &:not(:first-child):hover {
                    cursor: pointer;
                    background-color: #e0e9f2;
                }
            }
            .dropdown-item--auth {
                cursor: default;
                white-space: normal;
                &:active {
                    color: $dark-blue;
                }
                &:hover {
                    background-color: inherit;
                }
                &:first-child {
                    padding-bottom: 0px;
                    padding-top: 16px;
                }
                &:last-child {
                    padding-bottom: 16px;
                }
                > div {
                    display: flex;
                    flex-direction: column;
                }
            }
            .dropdown-item--underline {
                padding: 14px 14px 0px 14px;
                &:hover {
                    background-color: inherit;
                }
            }
            #auth-underline {
                border-top: solid 1px $very-light-pink;
            }
            #auth-login {
                font-size: 14px;
                button {
                    margin-top: 4px;
                    padding: 14px;
                    font-size: 16px;
                }
            }
            #auth-user {
                font-size: 14px;
                p {
                    margin: 0;
                    font-weight: bold;
                    font-size: 16px;
                }
                span {
                    color: $blue;
                }
            }
        }
        #my-account {
            background: transparent;
            border: none;
        }
        #dropdown-menu-mobile {
            > div {
                height: auto !important;
                opacity: 1 !important;
                box-shadow: $box-shadow;
                border-radius: $border-radius;
                position: relative;
                background-color: $pale-grey;
                margin-top: 5px;

                &::before {
                    content: '';
                    position: absolute;
                    top: -1rem;
                    left: unset;
                    right: 3.7rem;
                    transform: rotate(180deg);
                    border-width: 8px;
                    border-style: solid;
                    border-color: $pale-grey transparent transparent;
                    border-image: initial;
                    height: 0;
                    opacity: 1 !important;
                }
            }
        }
        #dropdown-menu-welcome {
            @extend #dropdown-menu-mobile;
            > div {
                width: fit-content;
            }
        }
    }
}

/// Footer
footer {
    background-color: $dark-blue;
    color: $white;
    padding: 1.5rem 0 1.5rem 0;
    a {
        color: $white;
        &:hover {
            color: darken($white, 10%);
        }
    }
    .company {
        line-height: 1;
        margin-bottom: 1rem;
        .contact {
            display: flex;
            a,
            span {
                font-size: 14px;
            }
            .line-contact {
                margin: 0px 2px;
                width: 16px;
                height: 16px;
            }
        }
    }
    .menu {
        ul {
            &.social-link {
                li {
                    a {
                        font-size: 1.5rem;
                    }
                }
            }
            li {
                a {
                    font-size: 0.875rem;
                }
            }
        }
    }
    .dealer-login {
        display: block;
        width: 100%;
        text-align: center;
        padding-top: 1rem;
        a {
            border-left: 0;
            border-top: 1px solid $very-light-pink;
            width: 100%;
            padding-left: 0;
            padding-top: 1rem;
            display: block;
        }
    }
}

// Homepage Search Banner
.banner-container {
    height: 512px;
    position: relative;
    background-size: cover;
    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(48, 55, 75, 0.8);
        position: absolute;
        z-index: 1;
    }
    .content-section {
        width: 100%;
        height: 100%;
        color: $white;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 52px;
        // padding-bottom: 60px;
        .heading-text {
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;
            h1 {
                font-weight: 700;
                img {
                    max-height: 4.5rem;
                }
            }
            h4 {
                font-weight: 400;
                font-size: 1rem !important;
            }
        }
        .search-box {
            &.sample {
                height: 230px;
                max-width: 100%;
                padding: 0 1rem;
                width: 450px;
                // background-color: rgba(0, 0, 0, 0.1);
            }
        }
        .contact-more {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            margin-top: 1.5rem;
            ul {
                li {
                    padding: 0 1rem;
                    margin: 0;
                    &:last-child {
                        border-left: 1px solid $white;
                        padding-left: 0.5rem;
                    }
                    a {
                        color: $white;
                    }
                }
            }
        }
        .valueprop {
            display: none;
            height: 0;
            padding: 24px 0;
            background-color: rgba(0, 0, 0, 0.3);
            width: 100%;
            text-align: center;
            position: absolute;
            bottom: 0;
            ul {
                margin: 0;
                li {
                    font-size: 0.875rem;
                    margin: 0 1.5rem;
                    &::before {
                        font-family: 'drivehub-icon';
                        content: '\f06d';
                        color: $green;
                        padding-right: 0.5rem;
                    }
                }
            }
        }
    }
}
/// Promotions
.promotions {
    padding: 2rem 0;
}

/// Car available section
.car-available {
    background-color: $pale-grey;
    padding: 1.5rem 0 1.5rem 0;
    h2 {
        font-size: 1.313rem;
        margin-bottom: 0;
    }
}
.cities-deal {
    //background-color: $pale-grey;
    padding: 1.5rem 0 2.5rem 0;
    h3 {
        margin-bottom: 0;
    }
}
.faq {
    padding: 1.5rem 0 1.5rem 0;
}

.services {
    padding: 1.5rem 0 0 0;
    background-color: $pale-grey;
    h2 {
        font-size: 1.313rem;
        margin-bottom: 1rem;
    }
}
.partners {
    padding: 1.5rem 0 1.5rem 0;
    background-color: $pale-grey;
    h2 {
        font-size: 1.313rem;
        margin-bottom: 1rem;
    }
}
.regions {
    padding: 1.5rem 0 1.5rem 0;
    .container {
        .row {
            > .col,
            > [class*='col-'] {
                &:nth-child(odd) {
                    padding-right: 0;
                }
            }
        }
    }
    h3 {
        margin-bottom: 1.5rem;
    }
    h4 {
        margin-bottom: 0.5rem;
    }
    ul {
        padding: 0 0 0 1rem;
        li {
            padding: 0.5rem 0;
            font-size: 0.875rem;
        }
    }
}
#manage-booking {
    padding: 2rem 0 2.5rem 0;
    background-color: $pale-grey;
    .form-control {
        margin-bottom: 0.5rem;
    }
}

@include media-breakpoint-down(md) {
    h1 {
        font-size: 1.625rem !important;
    }
    .wrapper {
        margin-left: -1rem;
        margin-right: -1rem;
        max-width: 100vw;
    }
    .container {
        width: 100% !important;
        max-width: 100% !important;
    }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-only(md) {
    .car-available,
    .regions,
    .partners,
    .services,
    #manage-booking {
        .container {
            width: 100%;
            max-width: 100%;
        }
    }
}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {
    .car-available,
    .about-drivehub,
    .regions,
    footer {
        padding: 2.5rem 0 2.5rem 0;
    }
    .services {
        padding: 2.5rem 0 0 0;
    }
    .partners {
        padding: 1.5rem 0 2.5rem 0;
    }
    .faq {
        padding: 2.5rem 0 2.5rem 0;
    }
    .card-container {
        &.scrollable {
            display: flex;
            overflow-x: hidden;
            scroll-snap-type: x proximity;
        }
        &.two-columns-fixed {
            width: 100%;
            padding: 4px 4px 0 1px;
            display: flex;
            flex-wrap: wrap;
            margin-right: -5px;
            margin-left: 0px;
            justify-content: center;
            .card {
                flex: 0 0 25%;
                max-width: calc(25% - 15px);
                display: flex;
                flex-direction: row;
                min-height: auto;
                margin: 0 0.8rem 1rem 0;
                &:nth-child(2n + 1) {
                    margin: 0 0.8rem 1rem 0;
                }
                .card-img {
                    display: none;
                    width: 40%;
                    height: 100%;
                    img {
                        border-radius: 0.3125rem 0rem 0rem 0.3125rem;
                    }
                }
                .card-body {
                    width: 60%;
                    padding: 0.875rem 1rem;
                    align-content: flex-start;
                    > div {
                        width: 100%;
                    }
                    .price {
                        max-width: 98px;
                    }
                    .btn {
                        width: auto;
                        margin-top: 1rem;
                    }
                }
            }
        }
        &.car {
            .card {
                width: 253px;
            }
        }
        &.two-columns {
            display: block !important;
            padding: 0 4px 0 0;
            .card {
                width: 100%;
                display: flex;
                flex-direction: row;
                margin: 0 0 0.5rem 0;
                flex-shrink: 2;
                .card-img {
                    width: 50%;
                    height: 180px;
                    img {
                        border-top-right-radius: 0;
                        border-bottom-left-radius: 0.3125rem;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .card-body {
                    width: 50%;
                    padding: 1.5rem;
                    align-content: flex-start;
                    > div {
                        width: 100%;
                    }
                    .btn {
                        width: auto;
                        margin-top: 1rem;
                    }
                }
            }
        }
        .card {
            &:first-child {
                margin-left: 1px;
            }
        }
    }
    #mainMenu {
        nav {
            padding: 0 1rem !important;
            #dropdown-auth-mobile {
                display: none;
            }
            #navbarNavDropdown {
                .navbar-nav {
                    padding: 0 0.5rem;
                    .nav-item {
                        &.dropdown {
                            &:hover,
                            &:focus {
                                > a {
                                    &::after {
                                        content: '\e817';
                                    }
                                }
                                .dropdown-menu {
                                    transform: scale(1);
                                    opacity: 1;
                                    height: auto;
                                    .dropdown-link {
                                        height: auto;
                                        opacity: 1;
                                        &::before {
                                            height: auto;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                            .dropdown-menu--welcome {
                                height: auto;
                                opacity: 1;
                                .dropdown-link {
                                    height: auto !important;
                                    &::before {
                                        opacity: 1 !important;
                                    }
                                }
                                &.show {
                                    left: 0px;
                                }
                            }
                        }
                        .nav-link {
                            padding: 0px 0.5rem;
                        }
                    }
                }
            }
        }
    }

    .banner-container {
        .content-section {
            padding-bottom: 28px;
            padding-top: 0 !important;
            .valueprop {
                display: block !important;
                height: auto !important;
            }
            .contact-more {
                display: none !important;
            }
        }
    }

    footer {
        .dealer-login {
            display: inline;
            width: auto;
            text-align: left;
            padding-top: 0;
            a {
                border-left: 1px solid $very-light-pink;
                padding-left: 0.5rem;
                border-top: 0;
                width: 100%;
                padding-top: 0;
                display: inline;
            }
        }
    }
}

.fetching {
    color: transparent !important;
    background-image: url('/assets/shares/loading.svg');
    background-size: 2rem 2rem;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    cursor: none;
}
