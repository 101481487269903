@import './base/base';

.flex {
    display: flex;
}

.loading-box {
    height: 45vh;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    box-shadow: none;
    &:active,
    &:hover,
    &:disabled,
    &:focus {
        background-color: #6c757d !important;
        border-color: #6c757d !important;
        box-shadow: none !important;
    }
}
.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}
