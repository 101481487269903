// Typography
//
// Font, line-height, and color for body text, headings, and more.
@import url('https://fonts.googleapis.com/css?family=Kanit:400,500,600|Roboto:400,700|Sarabun:400,700&display=swap');
//Flaticon
// @import url('~/assets/font/_flaticon.scss');

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Roboto', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

// h1,h2,h3,h4 {
//      font-family: 'Roboto','Kanit', sans-serif;
// }

$h1-font-size: $font-size-base * 1.875; //30px
$h2-font-size: $font-size-base * 1.5; // 24px
$h3-font-size: $font-size-base * 1.313; // 21px
$h4-font-size: $font-size-base * 1.125; // 18px
$h5-font-size: $font-size-base * 1; // 16px
$h6-font-size: $font-size-base * 0.875; // 14px

$headings-margin-bottom: $spacer / 2;
$headings-font-family: null;
$headings-font-weight: 600;
$headings-line-height: 1.3;
$headings-color: null;

$small-font-size: $font-size-base * 0.75; // 12px

$text-muted: $gray-600;
