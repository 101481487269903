@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import '../../../packages/ui/styles/_colors.scss';

$lg-screen: 992px;
$md-screen: 768px;
$sm-screen: 576px;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        'primary': $primary,
        'primary-light': $very-light-blue,
        'secondary': $secondary,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'light': $light,
        'dark': $dark,
        'local': $orange,
        'local-light': $pale,
        'inter': $deep-sea-blue,
        'inter-light': $very-light-blue-two,
        'alert-success': $soft-green,
        'alert-error': $soft-red
    ),
    $theme-colors
);

$yiq-contrasted-threshold: 200;

$link-color: theme-color('primary');
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25
        ),
        2: (
            $spacer * 0.5
        ),
        3: $spacer,
        4: (
            $spacer * 1.5
        ),
        5: (
            $spacer * 2
        )
    ),
    $spacers
);

// Grid
$grid-gutter-width: 2rem;

// Customize  Bootstrap 4 Variables

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $dark-blue;
$yiq-text-light: $white;

// Settings for the `<body>` element.
//
$body-bg: $white;
$body-color: $dark-blue;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius: 0.125rem;
$border-radius-lg: 0.3125rem;
$border-radius-sm: 0.125rem;

$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;

$box-shadow: 0 0.1875rem 0.375rem rgba($black, 0.23);

$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

// Navbar

$navbar-padding-y: $spacer / 1.6;

$nav-link-padding-y: 0.75rem;

$navbar-dark-color: $white;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(
    url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
    '#',
    '%23'
) !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: $dark-blue;
$navbar-light-hover-color: rgba($dark-blue, 0.7) !default;
$navbar-light-active-color: rgba($dark-blue, 0.9) !default;
$navbar-light-disabled-color: rgba($dark-blue, 0.3) !default;
$navbar-light-toggler-icon-bg: str-replace(
    url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
    '#',
    '%23'
) !default;
$navbar-light-toggler-border-color: rgba($dark-blue, 0.1) !default;

$navbar-toggler-padding-x: 0;
$navbar-toggler-padding-y: 0.5625rem;

// Dropdown menu container and contents.

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0;
$dropdown-spacer: 1rem;
$dropdown-font-size: 0.875rem;
$dropdown-bg: transparent;
$dropdown-border-color: none;
$dropdown-border-radius: $border-radius;
$dropdown-inner-border-radius: calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;

$dropdown-link-color: $dark-blue;
$dropdown-link-hover-color: $dark-blue;
$dropdown-link-hover-bg: darken($pale-grey, 4%);

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.9rem;
$dropdown-item-padding-x: 1.5rem;

$dropdown-header-color: $gray-600 !default;

// Forms
$input-color: $dark-blue;
$input-border-color: $very-light-pink;
$input-box-shadow: none;
$enable-validation-icons: false;

$input-border-radius: $border-radius;

$btn-padding-y-sm: 0.528rem;
$btn-padding-x-sm: 1rem;

$input-placeholder-color: $very-light-pink;
$input-padding-y: 0.875rem;
$input-padding-x: 1rem;
$input-line-height: 1.2;
$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});

$btn-padding-y: 0.875rem;
$btn-padding-x: 1rem;
$btn-font-size: 1.125rem;
$btn-line-height: 1;

// Cards
$card-border-width: 0;
$card-border-radius: $border-radius-lg;
$card-inner-border-radius: $card-border-radius;
$card-border-color: $white;
$card-spacer-y: 0.5rem;
$card-spacer-x: 0.5rem;

// @import 'src/packages/ui/styles/bootstrap';

.car-card--candidate {
    display: flex;
    flex-direction: row;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    margin: 10px 0;
    a {
        text-decoration: none !important;
        color: initial;
        :hover {
            // color: initial;
        }
    }

    .avatar {
        height: 263px;
        flex: 1;

        .dc_percent_display {
            position: absolute;
            right: 0;
            top: 10px;
            background-color: #e12d2d;
            color: #ffffff;
            font-weight: 700;
            padding: 3px 5px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    .avatar--with-badge {
        @media (min-width: 992px) {
            height: 306px;
        }
    }

    @media (max-width: $md-screen) {
        flex-direction: column;
    }
    section {
        position: relative;
    }
    section:first-child {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    > section:nth-child(2) {
        padding: 5px 10px;
        flex: 1;
    }
    header {
        padding-left: 5px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-bottom: 1px solid #ededed;
        margin-bottom: 5px;
        padding-bottom: 4px;
        a:hover {
            color: #000000;
        }
        h2 {
            font-size: 20px;
            margin-bottom: 4px;
            font-weight: bold;
            span {
                @media (max-width: 340px) {
                    font-size: 16px;
                }
            }
        }
        small {
            font-size: 10px;
            color: #9e9e9e;
        }
        .ready {
            color: #49a35c;
            font-size: 11px;
            font-weight: bold;
        }
        .instant_confirm {
            display: block;
            font-size: 10px;
            text-decoration: underline;
            text-align: right;
            cursor: pointer;
            img {
                height: 14px;
            }
        }
    }
    .body {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
    }

    .details {
        display: flex;
        flex-direction: row;
        font-size: 10px;
        div {
            margin-right: 12px;
        }
        :before {
            margin-left: 0px;
            font-size: 10px;
        }
        .luggage_icon {
            height: 10px;
            width: 13px;
            margin-bottom: 2px;
        }
        .baggage_icon {
            height: 12px;
            width: 15px;
            margin-bottom: 2px;
        }
    }
    .badge {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 3px;
        padding: 2px 3px;
        font-size: 10px;
        font-weight: 400;
        border-radius: 3px;
        text-align: left;

        img {
            height: 14px !important;
        }
        div {
            flex: 1;
        }
        :first-child {
            margin-right: 2px;
        }
        ::before {
            font-size: 10px;
            margin-left: 3px;
        }
    }
    .datetime {
        font-size: 10px;
        span {
            display: block;
        }
    }
    .valueprops {
        font-size: 10px;
        margin-top: 15px;
        line-height: 1.7;
        p {
            margin-bottom: 0px;
        }
        :before {
            margin-left: 0px;
            font-size: 10px;
            color: #49a35c;
            margin-right: 5px;
        }
        .flaticon-information::before {
            color: #000;
        }
    }
    .align-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: left;
    }
    .price {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid #dddddd;
        border-radius: 3px;
        background-color: #eeeeee;
        padding: 3px 10px;
        font-size: 10px;
        max-width: 110px;
        line-height: 1.2;
        h3 {
            color: #0078ff;
            font-size: 26px;
            margin-bottom: 0px;
            font-weight: bold;
            small {
                font-size: 10px;
                margin-left: 3px;
            }
        }
        .total {
            font-weight: bold;
            font-size: 12px;
        }
        .slash-price {
            line-height: 0.5;
            margin-top: 5px;
            span {
                font-size: 12px;
                text-decoration: line-through;
                text-decoration-color: #d20000;
            }

            h3 {
                color: #d0021b;
            }
        }
    }
    .freecancel {
        color: #49a35c;
        font-size: 10px;
        margin-top: 15px;
        cursor: pointer;
    }
    .vendor {
        display: flex;
        position: relative;
        width: fit-content;
        // min-width: 120px;
        align-items: flex-end;
        margin-top: 15px;
        font-size: 12px;
        line-height: 1;
        cursor: pointer;
        @media screen and (max-width: 1200px) {
            font-size: 11px;
        }
        i::before {
            margin-left: 4px;
            font-size: 12px;
            color: #0053ba;
        }
        &.vendor--inter {
            @media screen and (max-width: 992px) {
                margin-top: 25px;
            }
            @media screen and (max-width: 768px) {
                margin-top: 15px;
            }
            @media screen and (max-width: 375px) {
                margin-top: 30px;
            }
        }
        &.vendor--local {
            @media screen and (max-width: 1200px) {
                margin-top: 25px;
            }
            @media screen and (max-width: 768px) {
                margin-top: 15px;
            }
            @media screen and (max-width: 375px) {
                margin-top: 25px;
            }
        }
        &.rating-empty {
            @media screen and (max-width: 375px) {
                margin-top: 25px;
            }
        }

        .vendor__logo {
            .logo {
                width: 38px;
                height: 38px;
                margin-right: 5px;
                object-fit: contain;
                border: 1px solid #ededed;
                @media screen and (max-width: 1200px) {
                    margin-right: 5px;
                }
            }
        }

        .vendor__detail {
            position: relative;
            .name {
                padding-bottom: 6px;
            }
            .badge {
                margin-top: -1px;
            }
        }

        .rating-badge {
            position: absolute;
            top: -3px;
            left: 110%;
            width: max-content;
            &.empty {
                .score {
                    background-color: #c3c3c3;
                    padding: 4px 6px;
                    &:before {
                        border-right-color: #c3c3c3;
                    }
                }
                .level {
                    color: #30374b;
                }
            }

            .score {
                width: fit-content;
                padding: 4px;
                border-radius: 4px;
                color: #fff;
                background-color: #0078ff;
                font-size: 14px;
                span {
                    font-size: 10px;
                }
                @media screen and (max-width: 1200px) {
                    font-size: 13px;
                }
            }
            .score:before {
                right: 100%;
                top: 50%;
                border: solid transparent;
                content: ' ';
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: none;
                border-right-color: #0078ff;
                border-width: 4px;
                margin-top: -13px;
            }
            .level {
                padding-top: 4px;
                font-weight: bold;
            }
            .total-review {
                font-size: 10px;
                font-weight: normal;
                color: #6f6f6f;
            }
        }
    }

    .rent-button {
        margin-top: 5px;
        width: 100%;
        padding: 12px 16px;
        font-size: 12px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        color: #fff;
        :hover {
            color: #fff;
        }
    }
    .zeroexcess {
        border: 1px solid #ffcb14;
        border-left: 3px solid #ffcb14;
        border-radius: 3px;
        background-color: #fffef8;
        display: flex;
        padding: 4px 10px;
        font-size: 11px;
        cursor: pointer;
        color: #59480c;

        @media (min-width: 992px) and (max-width: 1199px) {
            font-size: 10px;
        }

        img {
            margin-right: 15px;
        }
        p {
            margin: 0px;
        }
        ::before {
            font-size: 10px;
            margin-left: 0px;
        }
    }
}
